import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Badge, NotificationBadge, StatusBadge, BulletBadge } from '@entur/layout';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@entur/tab';
import { Table, TableHead, TableBody, TableRow, HeaderCell, DataCell } from '@entur/table';
import { VisuallyHidden } from '@entur/a11y';
import { SecondarySquareButton, NegativeButton } from '@entur/button';
import { Heading3, Heading2, Paragraph } from '@entur/typography';
import { PrimaryButton } from '@entur/button';
import { TextField, TextArea } from '@entur/form';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { SubtractIcon } from '@entur/icons';
import { badgeprops } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<Badge variant=\"primary\">1</Badge>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Badge,
      NotificationBadge,
      StatusBadge,
      BulletBadge,
      Tabs,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      Table,
      TableHead,
      TableBody,
      TableRow,
      HeaderCell,
      DataCell,
      VisuallyHidden,
      SecondarySquareButton,
      NegativeButton,
      Heading3,
      Heading2,
      Paragraph,
      PrimaryButton,
      TextField,
      TextArea,
      Playground,
      PageHeader,
      ImportStatement,
      SubtractIcon,
      badgeprops,
      DefaultLayout,
      _frontmatter
    }} props={badgeprops} mdxType="Playground">
  <Badge variant="primary" mdxType="Badge">1</Badge>
    </Playground>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <h3 {...{
      "id": "bullet-badge"
    }}>{`Bullet badge`}</h3>
    <p><inlineCode parentName="p">{`<BulletBadge>`}</inlineCode>{` er en tekstkomponent med en farget punkttegn.
Den kan brukes for visning av status og bør ikke inneholde lange setninger.
Komponenten kan for eksempel brukes for å vise status i en liste eller tabell etc.`}</p>
    <p>{`Komponenten tilbys i ulike farger for å indikere hvilke status et objekt eller element har.
Sørg for at bakgrunnsfargen har nok kontrast til fargen på punkttegnet.`}</p>
    <h4 {...{
      "id": "eksempel"
    }}>{`Eksempel`}</h4>
    <Playground __position={2} __code={'<Table>\n  <TableHead>\n    <TableRow>\n      <HeaderCell>Beskrivelse</HeaderCell>\n      <HeaderCell>Status</HeaderCell>\n      <HeaderCell>Publisering start og slutt</HeaderCell>\n      <HeaderCell>Opprettet av</HeaderCell>\n    </TableRow>\n  </TableHead>\n  <TableBody>\n    <TableRow>\n      <DataCell>Kan Entur vise reklame i mobilappen?</DataCell>\n      <DataCell>\n        <BulletBadge variant=\"success\">Aktiv</BulletBadge>\n      </DataCell>\n      <DataCell>\n        01.04.2020 - <br /> 01.01.2022\n      </DataCell>\n      <DataCell>Entur</DataCell>\n    </TableRow>\n    <TableRow>\n      <DataCell>Kan Entur sende deg tilbud og nyheter på e-post?</DataCell>\n      <DataCell>\n        <BulletBadge variant=\"neutral\">Kladd</BulletBadge>\n      </DataCell>\n      <DataCell>-</DataCell>\n      <DataCell>Entur</DataCell>\n    </TableRow>\n    <TableRow>\n      <DataCell>\n        Kan Kringom sende deg e-post medkvitteringer og billetter?\n      </DataCell>\n      <DataCell>\n        <BulletBadge variant=\"negative\">Utløpt</BulletBadge>\n      </DataCell>\n      <DataCell>02.02.2020</DataCell>\n      <DataCell>Kringom</DataCell>\n    </TableRow>\n  </TableBody>\n</Table>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Badge,
      NotificationBadge,
      StatusBadge,
      BulletBadge,
      Tabs,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      Table,
      TableHead,
      TableBody,
      TableRow,
      HeaderCell,
      DataCell,
      VisuallyHidden,
      SecondarySquareButton,
      NegativeButton,
      Heading3,
      Heading2,
      Paragraph,
      PrimaryButton,
      TextField,
      TextArea,
      Playground,
      PageHeader,
      ImportStatement,
      SubtractIcon,
      badgeprops,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Table mdxType="Table">
    <TableHead mdxType="TableHead">
      <TableRow mdxType="TableRow">
        <HeaderCell mdxType="HeaderCell">Beskrivelse</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Status</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Publisering start og slutt</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Opprettet av</HeaderCell>
      </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">Kan Entur vise reklame i mobilappen?</DataCell>
        <DataCell mdxType="DataCell">
          <BulletBadge variant="success" mdxType="BulletBadge">Aktiv</BulletBadge>
        </DataCell>
        <DataCell mdxType="DataCell">
          01.04.2020 - <br /> 01.01.2022
        </DataCell>
        <DataCell mdxType="DataCell">Entur</DataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">Kan Entur sende deg tilbud og nyheter på e-post?</DataCell>
        <DataCell mdxType="DataCell">
          <BulletBadge variant="neutral" mdxType="BulletBadge">Kladd</BulletBadge>
        </DataCell>
        <DataCell mdxType="DataCell">-</DataCell>
        <DataCell mdxType="DataCell">Entur</DataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">
          Kan Kringom sende deg e-post medkvitteringer og billetter?
        </DataCell>
        <DataCell mdxType="DataCell">
          <BulletBadge variant="negative" mdxType="BulletBadge">Utløpt</BulletBadge>
        </DataCell>
        <DataCell mdxType="DataCell">02.02.2020</DataCell>
        <DataCell mdxType="DataCell">Kringom</DataCell>
      </TableRow>
    </TableBody>
  </Table>
    </Playground>
    <h3 {...{
      "id": "status-badge"
    }}>{`Status badge`}</h3>
    <p><inlineCode parentName="p">{`StatusBadge`}</inlineCode>{` er en visuell indikator som brukes til å vise statusen til et element.
Komponenten tilbys i ulike farger for å indikere hvilke status et element har.
`}<inlineCode parentName="p">{`StatusBadge`}</inlineCode>{` kan gi melding om advarsler, bekreftelser eller om noe er feil.
Vi kan for eksempel bruke denne komponenten for å indikere at en billett er utløpt, et forfalt eller betalt beløp, avvik, utdatert oppgjør etc.
`}<inlineCode parentName="p">{`StatusBadge`}</inlineCode>{` i farger benyttes gjerne til dynamisk statusmarkering, som er et resultat av insidenter/hendelser.`}</p>
    <p><inlineCode parentName="p">{`StatusBadge`}</inlineCode>{` i en default/neutral variant kan brukes for å kategorisere eller organisere elementer ved hjelp av nøkkelord som beskriver dem.
Bruk denne varianten når har mange kategorier, og brukeren trenger en måte å skille mellom dem.
For enkel skanning av de ulike, bør teksten ikke være på mer enn to ord.
`}<inlineCode parentName="p">{`StatusBadge`}</inlineCode>{` default/neutral benyttes gjerne til en mer statisk markering, som baserer seg på kategorisering.`}</p>
    <p>{`Sørg for at bakgrunnsfargen har nok kontrast til fargen på `}<inlineCode parentName="p">{`StatusBadge`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "notification-badge"
    }}>{`Notification badge`}</h3>
    <p><inlineCode parentName="p">{`NotificationBadge`}</inlineCode>{` er en visuell indikator for numeriske verdier.
Denne komponenten er egnet for å vise et varsel på et UI-element som kan kreve brukerens oppmerksomhet og som de bør ta hensyn til.`}</p>
    <p>{`En `}<inlineCode parentName="p">{`NotificationBadge`}</inlineCode>{` bør plasseres øverst til høyre relativt til elementet `}<inlineCode parentName="p">{`NotificationBadge`}</inlineCode>{` tilhører.
Unngå å bruk komponenten alene.`}</p>
    <h3 {...{
      "id": "eksempel-1"
    }}>{`Eksempel`}</h3>
    <Playground __position={3} __code={'<div>\n  <div>\n    <Tabs>\n      <TabList>\n        <Tab style={{ position: \'relative\', minWidth: \'12rem\' }}>Ordre</Tab>\n        <Tab style={{ position: \'relative\', minWidth: \'12rem\' }}>\n          Historikk\n        </Tab>\n        <Tab style={{ position: \'relative\', minWidth: \'12rem\' }}>\n          Merknader\n          <NotificationBadge\n            variant=\"primary\"\n            style={{ position: \'absolute\', top: 0 }}\n            max={10}\n          >\n            1 <VisuallyHidden>notifikasjon</VisuallyHidden>\n          </NotificationBadge>\n        </Tab>\n      </TabList>\n      <TabPanels>\n        <TabPanel>\n          <Heading2 as=\"span\">Dine ordre</Heading2>\n          <StatusBadge variant=\"neutral\">Ingen nye ordre</StatusBadge>\n        </TabPanel>\n        <TabPanel></TabPanel>\n        <TabPanel></TabPanel>\n      </TabPanels>\n    </Tabs>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Badge,
      NotificationBadge,
      StatusBadge,
      BulletBadge,
      Tabs,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      Table,
      TableHead,
      TableBody,
      TableRow,
      HeaderCell,
      DataCell,
      VisuallyHidden,
      SecondarySquareButton,
      NegativeButton,
      Heading3,
      Heading2,
      Paragraph,
      PrimaryButton,
      TextField,
      TextArea,
      Playground,
      PageHeader,
      ImportStatement,
      SubtractIcon,
      badgeprops,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <div>
      <Tabs mdxType="Tabs">
        <TabList mdxType="TabList">
          <Tab style={{
                position: 'relative',
                minWidth: '12rem'
              }} mdxType="Tab">Ordre</Tab>
          <Tab style={{
                position: 'relative',
                minWidth: '12rem'
              }} mdxType="Tab">
            Historikk
          </Tab>
          <Tab style={{
                position: 'relative',
                minWidth: '12rem'
              }} mdxType="Tab">
            Merknader
            <NotificationBadge variant="primary" style={{
                  position: 'absolute',
                  top: 0
                }} max={10} mdxType="NotificationBadge">
              1 <VisuallyHidden mdxType="VisuallyHidden">notifikasjon</VisuallyHidden>
            </NotificationBadge>
          </Tab>
        </TabList>
        <TabPanels mdxType="TabPanels">
          <TabPanel mdxType="TabPanel">
            <Heading2 as="span" mdxType="Heading2">Dine ordre</Heading2>
            <StatusBadge variant="neutral" mdxType="StatusBadge">Ingen nye ordre</StatusBadge>
          </TabPanel>
          <TabPanel mdxType="TabPanel"></TabPanel>
          <TabPanel mdxType="TabPanel"></TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  </div>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "bulletbadge"
    }}>{`BulletBadge`}</h3>
    <ImportStatement imports="BulletBadge" mdxType="ImportStatement" />
    <Props of={BulletBadge} mdxType="Props" />
    <h3 {...{
      "id": "statusbadge"
    }}>{`StatusBadge`}</h3>
    <ImportStatement imports="StatusBadge" mdxType="ImportStatement" />
    <Props of={StatusBadge} mdxType="Props" />
    <h3 {...{
      "id": "notificationbadge"
    }}>{`NotificationBadge`}</h3>
    <ImportStatement imports="NotificationBadge" mdxType="ImportStatement" />
    <Props of={NotificationBadge} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      